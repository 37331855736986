.dashboard {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	color: $accent-color;
	font-family: Poppins, sans-serif;
	margin: 0;
	box-sizing: border-box;
	background-color: $light-blue-color;
	display: grid;
	grid-template-columns: 300px min-content;

	h1,
	h2,
	h3,
	h4,
	h5,
	p {
		margin: 0;
		padding: 0;
		font-weight: 400;
	}

	a,
	button {
		text-decoration: none;
		cursor: pointer;
	}

	ul {
		list-style-type: none;
		padding-left: 0;
		margin-block-start: 0;
		margin-block-end: 0;
	}

	hr {
		margin-block-start: 0;
		margin-block-end: 0;
		margin: 0;
		border: none;
		border-top: 1px solid $border-color;
	}

	.sidebar {
		min-width: 300px;
	}

	&.client {
		display: block;
		overflow: auto;

		.request_item {
			margin: 0 auto;
			padding: 40px;
			width: 586px;

			.icon.border.close {
				display: none;
			}

			.header_block h2 {
				padding-left: 0px;
			}
		}
	}

	.main_area {
		min-width: 662px;
	}

	.subinfo {
		opacity: 50%;
	}

	.icon {
		display: block;
		width: 24px;
		height: 24px;
		background-repeat: no-repeat;
		background-position: center center;

		&.border {
			box-sizing: border-box;
			border: 1px solid $accent-color;
			border-radius: 8px;
			display: flex;
			width: 40px;
			height: 40px;
			cursor: pointer;

			&.red {
				border: 1px solid $error-color;
			}
		}

		&.close {
			background-image: url('#{$img-url}/close_big.svg');
		}

		&.edit {
			margin-right: 8px;
			background-image: url('#{$img-url}/edit.svg');
		}

		&.print {
			background-image: url('#{$img-url}/print.svg');
		}

		&.close_small {
			background-image: url('#{$img-url}/close_small.svg');
		}

		&.close_red {
			background-image: url('#{$img-url}/close_red.svg');
		}

		&.approve {
			background-image: url('#{$img-url}/approve.svg');
		}

		&.unseen {
			background-image: url('#{$img-url}/icon_eye_cross_big.svg');
		}

		&.seen {
			background-image: url('#{$img-url}/icon_eye_big.svg');
		}

		&.arrow_right_requests {
			background-image: url('#{$img-url}/arrow_right_requests.svg');
		}

		&.delete {
			background-image: url('#{$img-url}/delete.svg');
		}

		&.delete_red {
			background-image: url('#{$img-url}/delete_red.svg');
		}

		&.refresh {
			background-image: url('#{$img-url}/refresh.svg');
		}

		&.export {
			background-image: url('#{$img-url}/export.svg');
		}

		&.download {
			background-image: url('#{$img-url}/download.svg');
		}
	}

	input[type='file'] {
		display: none;
	}

	input[type='number'] {
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	.button,
	button:not(.rsw-btn, .rsw-btn-link) {
		@include button(desktop);
		box-sizing: border-box;
		padding: 8.5px 23px;
		width: fit-content;
		border: 1px solid $accent-color;
		color: $accent-color;
		line-height: 21px;

		background-color: transparent;
		font-size: 14px;

		&.warning {
			color: $error-color;
			border-color: $error-color;
		}

		&[type='submit'],
		&.approve {
			background-color: $accent-color;
			color: $white;
		}
	}

	form {
		display: flex;
		flex-direction: column;

		label {
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			opacity: 50%;
			padding-bottom: 4px;
		}

		textarea,
		.rsw-ce {
			@include textInput(desktop);
			height: auto;
			min-height: 80px;
			overflow: auto;

			width: 506px;

			resize: none;

			&:focus {
				border-color: $link-color;
			}

			&::placeholder {
				color: $gray-color;
			}
		}

		.rsw-editor {
			width: 506px;

			.rsw-ce {
				display: block;
				border: 0px;
				width: 504px;
			}
		}

		.input {
			@include inputError();
			display: flex;
			flex-direction: column;
			position: relative;

			input {
				@include textInput(desktop);
				height: 40px;
				font-size: 14px;

				&[type='file'] {
					display: none;
				}

				-moz-appearance: textfield;
			}
		}

		.actions {
			display: flex;
			gap: 16px;
			white-space: pre;

			button {
				flex-basis: 50%;
			}
		}
	}

	.header_block {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.tabs {
			display: flex;
			flex-wrap: wrap;
			list-style-type: none;
			font-size: 14px;
			line-height: 21px;

			a {
				padding: 9.5px 16px;

				display: flex;
				align-items: center;
				color: $accent-color;

				transition: background 150ms ease-in-out;

				.counter {
					margin-left: 8px;
				}

				&.active {
					background-color: $background-accent;
					font-weight: 600;
					border-radius: 8px;
				}
			}
		}
	}

	.dashboard_right_section {
		position: fixed;
		right: 0px;
		top: 0px;
		bottom: 0px;
		min-width: 520px;
		width: calc(100vw - 1030px);
		padding: 40px;
		background-color: $white;
		overflow-y: scroll;

		display: none;

		&.shown {
			display: block;
		}

		&::-webkit-scrollbar {
			width: 0;
		}

		.header_block {
			justify-content: space-between;
			height: 48px;
			margin-bottom: 8px;

			h2 {
				flex-grow: 1;
				margin-right: auto;
				padding-left: 16px;
				align-self: baseline;
				font-size: 32px;
				line-height: 48px;
				font-weight: 400;
			}

			.actions {
				display: flex;
				justify-content: space-between;
			}
		}
	}

	.dashboard_list {
		padding: 40px;
		overflow-y: scroll;
		height: 100vh;
		box-sizing: border-box;

		&::-webkit-scrollbar {
			width: 0;
		}

		h1 {
			font-size: 32px;
			line-height: 48px;
			font-weight: 400;
		}

		.counter {
			font-size: 10px;
			font-weight: 400;
			padding: 2px 6px;
			border-radius: 10px;
			background-color: $error-color;
			margin-left: 5px;
			text-align: center;
			line-height: 15px;
			vertical-align: middle;
			color: $white;
			min-width: 19px;
			display: flex;
			box-sizing: border-box;
			justify-content: center;
			align-items: center;
		}

		.no_items {
			font-size: 14px;
			line-height: 21px;
		}

		.list {
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 2px;

			li {
				box-sizing: border-box;
				padding: 15.5px 16px;
				font-size: 12px;
				line-height: 18px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid $border-color;

				.status {
					font-size: 12px;
					line-height: 18px;
					opacity: 50%;
					margin-left: auto;
					margin-right: 40px;
					font-weight: 400;
				}

				&.borderless {
					border-color: transparent;
				}

				&.active,
				&.unread {
					border-radius: 8px;
				}

				.info {
					white-space: pre-line;
					max-width: 270px;
					display: flex;
					flex-direction: column;
					gap: 4px;

					.rooms {
						span {
							display: block;
						}

						a {
							display: block;
							color: $link-color;
						}
					}
				}

				.actions {
					display: flex;
				}

				&.unread {
					border-color: $white;
					background-color: $white;

					> .info > p {
						font-weight: 600;
					}
				}

				&.active {
					border-color: $background-accent;
					background-color: $background-accent;

					.actions {
						display: none;
					}

					.status {
						padding-right: 40px;
					}
				}
			}
		}
	}
}
