.app2 {
	.search_calendar {
		width: 675px;
		margin: 40px auto 0;

		h1 {
			@include header(desktop);
			margin: 0 0 16px;
		}

		.wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 41px;

			.calendar_content {
				height: min-content;

				&.desktop {
					border-color: $border-color;
				}
			}

			.search {
				width: 100%;

				.search_fields {
					.calendar {
						&.mobile {
							display: none;
						}
					}

					.input {
						position: relative;

						> input {
							@include textInput(desktop);

							background: $light-gray-color url('#{$img-url}/search.svg') no-repeat left;
							background-position: 8px 6px;
							background-size: 18px;

							padding-left: 34px !important;

							&:focus {
								border-color: $link-color;
							}

							&:not(:placeholder-shown) {
								background-color: white;

								+ .btn_close {
									display: block;
								}
							}

							&::placeholder {
								color: $gray-color;
							}
						}

						.btn_close {
							display: none;
							position: absolute;
							content: '';
							top: 6px;
							right: 8px;
							width: 18px;
							height: 18px;
							background: url('#{$img-url}/close.svg') center no-repeat;
						}
					}
				}

				.suggestions {
					margin: 16px 0;
					padding: 0;

					li {
						@include text(desktop);
						list-style: none;

						border-radius: 8px;
						padding: 12px;

						&:nth-child(odd) {
							background-color: $light-gray-color;
						}

						.schedule {
							display: flex;
							justify-content: space-between;
							margin-bottom: 8px;

							.time {
								text-transform: uppercase;
							}
						}

						.cabinet {
							span {
								display: block;
							}

							a {
								display: block;
								color: $link-color;
								text-decoration: none;
							}
						}

						&.unavailable {
							color: $gray-color;
						}

						&:hover {
							background-color: $light-blue-color;
						}
					}
				}

				.no_sessions_warning,
				.no_match_warning,
				.error {
					@include text(desktop);
					margin-top: 16px;
				}

				.total {
					@include grayText(desktop);
					border-top: 1px solid $border-color;
					padding-top: 8px;
					padding-bottom: 36px;
				}

				.error {
					display: block;
					color: $error-color;
				}
			}
		}
	}
}

@media (width <= 767px) {
	.app2 {
		height: auto;

		.search_calendar {
			width: 100%;
			margin: 48px 0 34px;
			padding-top: 20px;

			h1 {
				@include header(mobile);
				padding-left: 20px;
			}

			.wrapper {
				display: block;

				.calendar_content {
					&.desktop {
						display: none;
					}
				}

				.search {
					.search_fields {
						flex-direction: column-reverse;
						display: flex;
						gap: 20px;
						padding: 0 20px;

						.calendar {
							&.mobile {
								display: block;

								.selection {
									height: 38px;
									padding: 7px 8px;

									&::after {
										top: 16px;
									}
								}

								.calendar_content {
									top: calc(38px + 8px);
								}
							}

							&.open {
								.selection {
									&::after {
										top: 15px !important;
									}
								}
							}
						}

						.input {
							width: 100%;

							> input {
								@include textInput(mobile);
								background-position: 8px 10px;
								height: 38px !important;
								border-radius: 12px;
							}

							.btn_close {
								top: 10px;
								right: 10px;
							}
						}
					}

					.suggestions {
						li {
							padding: 16px 20px;
							border-radius: none;

							.place,
							.cabinet {
								@include text(mobile);
							}
						}
					}

					.no_sessions_warning,
					.no_match_warning,
					.error {
						@include text(mobile);
						padding: 0 20px;
					}

					.total {
						@include grayText(mobile);
						margin: 0 20px;
					}
				}
			}

			&.searching {
				.search_fields {
					.input {
						.btn_close {
							display: block !important;
						}
					}

					.calendar {
						&.mobile {
							display: none !important;
						}
					}
				}
			}
		}
	}
}
