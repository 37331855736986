.dashboard {
	.dashboard_list.requests_list {
		.header_block {
			margin-bottom: 16px;

			.month_picker {
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: $white;
				border: 1px solid $scrollbar-color;
				border-radius: 8px;
				margin-left: auto;
				height: 40px;
				box-sizing: border-box;
				width: 220px;

				span {
					padding: 10px 16px;
					font-size: 14px;
					line-height: 21px;
				}
				//
				a {
					padding: 4px;
					width: 20px;
					height: 20px;
				}

				.arrow_left {
					background: url('#{$img-url}/arrow_left_stepper.svg') center no-repeat;
				}

				.arrow_right {
					background: url('#{$img-url}/arrow_left_stepper.svg') center no-repeat;
					transform: scaleX(-1);
				}

				a.disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}

			h1 {
				margin-bottom: 24px;
				width: 100%;
			}
		}

		.no_items {
			padding: 16px;
		}
	}

	.request_item {
		background-color: $white;

		label {
			cursor: pointer;
		}

		h3 {
			font-size: 18px;
			line-height: 28px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			a {
				font-size: 12px;
				margin-left: 10px;
				color: $link-color;
			}
		}

		h4 {
			font-size: 14px;
			line-height: 21px;
			margin-top: 24px;
			margin-bottom: 8px;

			a {
				color: #14a2e2;
			}
		}

		.sending {
			display: block;
			font-size: 10px;
			line-height: 12px;
			height: 12px;
			color: $gray-color;
			font-family: 'Microsoft Sans Serif';
			margin-top: 8px;

			&.error {
				color: $error-color;
			}
		}

		ul > .description {
			padding-bottom: 16px;
		}

		.header_block {
			justify-content: space-between;
			height: 48px;
			margin-bottom: 8px;

			h2 {
				flex-grow: 1;
				margin-right: auto;
				padding-left: 16px;
				align-self: baseline;
				font-size: 32px;
				line-height: 48px;
				font-weight: 400;
			}

			.actions {
				display: flex;
				justify-content: space-between;
			}
		}

		.statuses {
			margin-bottom: 40px;

			li {
				font-size: 12px;
				line-height: 18px;
				opacity: 50%;
			}
		}

		.description {
			font-size: 12px;
			line-height: 18px;
			opacity: 50%;
			margin-bottom: 8px;
		}

		dl {
			margin-top: 8px;
			margin-bottom: 24px;
			font-size: 12px;
			line-height: 18px;

			display: grid;
			grid-template-columns: 160px auto;
			row-gap: 8px;
			column-gap: 24px;
		}

		dt {
			opacity: 80%;
			white-space: nowrap;
			min-width: 50%;

			&.checkbox {
				opacity: 55%;
			}

			&.wrap {
				white-space: wrap;
			}
		}

		dd {
			margin-inline-start: 0;

			&.checkbox {
				font-size: 14px;
			}

			> a {
				color: $link-color;
			}
		}

		.text_value {
			font-size: 12px;
			line-height: 18px;
			opacity: 50%;
			margin-bottom: 22px;
		}

		.optional {
			padding-top: 8px;
		}

		.block {
			margin-top: 24px;
			margin-bottom: 40px;
			white-space: pre-line;

			li {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 8px 0px 7px;
				border-bottom: 1px solid $border-color;
				border-top: 1px solid $border-color;
				margin-bottom: -1px;
				font-size: 12px;
				line-height: 18px;

				&:first-child {
					border-top: 0px;
				}

				&:last-child {
					border-bottom: 0px;
				}

				.info {
					white-space: pre-line;
					display: flex;
					flex-direction: column;
					gap: 4px;
				}
			}

			&.files {
				a {
					color: inherit;
					text-decoration: none;
				}

				.upload_info {
					font-size: 14px;
					line-height: 21px;
					opacity: 50%;
					padding-left: 12px;
				}

				.description {
					padding-top: 4px;
					margin-bottom: 16px;
				}

				ul {
					margin-bottom: -7px;

					li {
						border-top: none;

						.actions {
							display: flex;
							gap: 4px;

							.icons {
								display: flex;
								justify-content: center;
								gap: 4px;
								height: 24px;
								align-items: center;
							}
						}

						.confirmation {
							display: block;
							font-size: 8px;
							line-height: 12px;
						}
					}
				}
			}

			&.comments {
				li {
					padding-top: 24px;
					padding-bottom: 0px;
					font-size: 12px;
					line-height: 18px;
					display: block;
					border: 0px;

					h5 {
						font-weight: 600;
						font-size: 12px;
						line-height: 18px;
					}

					div {
						max-width: 400px;

						&.collapsed {
							max-height: 54px;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}

					a {
						color: #14a2e2;
					}

					> a {
						margin-bottom: 8px;
					}
				}

				form {
					padding-top: 24px;

					.rsw-editor {
						margin-bottom: 24px;
					}

					button {
						color: $accent-color;
						background-color: transparent;
					}
				}
			}

			&.notifications {
				ul {
					padding-top: 16px;

					li {
						padding-left: 8px;
						padding-right: 8px;

						.info {
							gap: 4.5px;
						}

						&:has(+ .seen) {
							border-color: transparent;
						}

						&.unseen {
							border-color: transparent;
							background-color: $background-accent;
							border-radius: 8px;

							& + li {
								border-top-color: transparent;
							}

							p {
								font-weight: 600;
							}
						}
					}
				}
			}

			&.custom {
				margin-top: 8px;
				margin-bottom: 24px;
				font-size: 12px;
				line-height: 18px;

				display: grid;
				grid-template-columns: minmax(160px, min-content) auto;
				row-gap: 8px;
				column-gap: 24px;

				h3 {
					grid-column: span 2;
				}

				h4 {
					grid-column: span 2;
					margin-top: 16px;
					margin-bottom: 0px;
				}

				hr {
					grid-column: span 2;
					margin-top: 16px;
				}
			}
		}

		.button {
			display: inline-block;
		}

		.buttons {
			height: 40px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 16px;

			.button {
				flex-grow: 1;
				text-align: center;

				&.warning {
					padding: 8.5px 24px;
				}

				&.approve {
					padding: 8.5px 19px;
					padding-left: 19px;
					padding-right: 19px;
				}
			}
		}
	}
}
